import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class AuthService {
  constructor(private http: HttpClient) {}

  login(data: any) {
    return this.http.post(environment.endPoint + '/login/', data);
  }

  refreshToken(data: any): Observable<any> {
    return this.http.post(environment.endPoint + '/login/refresh/', data);
  }
  signup(data: any) {
    return this.http.post(environment.endPoint + '/register/', data);
  }

  getUsersById(id: any) {
    return this.http.get(environment.endPoint + '/users/' + id + '/');
  }
  getUsersByPermissionId(id: any) {
    return this.http
      .get(environment.endPoint + '/users/' + id + '/')
      .subscribe((data: any) => {
        return data;
      });
  }
  getAllPermissions() {
    return this.http.get(environment.endPoint + '/permissions/');
  }
}
