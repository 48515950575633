import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse,
  HttpEvent,
} from '@angular/common/http';

import {
  throwError as observableThrowError,
  Observable,
  BehaviorSubject,
} from 'rxjs';
import { take, filter, catchError, switchMap, finalize } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { AuthService } from '@modules/auth/services/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  isRefreshingToken: Boolean = false;
  tokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private authenticationService: AuthService,
    private cookie: CookieService,
    private toaster: ToastrService,
  ) {}

  addToken(req: HttpRequest<any>, token: any): HttpRequest<any> {
    return req.clone({ setHeaders: { Authorization: 'Bearer ' + token } });
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(this.addToken(request, this.cookie.get('access'))).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse) {
          switch ((<HttpErrorResponse>error).status) {
            case 400:
              return this.handle400Error(error);
            case 401:
              return this.handle401Error(request, next, error);
            // default:
            //   return this.validationFailed(error);
          }
        }
        return observableThrowError(error);
      }),
    );
  }

  validationFailed(error: any) {
    this.toaster.error('error', 'Opps!', error.error.detail);
    return observableThrowError(error);
  }

  handle400Error(error: any) {
    if (
      error &&
      error.status === 400 &&
      error.error &&
      error.error.error === 'invalid_grant'
    ) {
      // If we get a 400 and the error message is 'invalid_grant', the token is no
      return this.logout();
    }
    return observableThrowError(error);
  }

  handle401Error(req: HttpRequest<any>, next: HttpHandler, error: any) {
    // console.log(error.error.detail)
    this.toaster.error(error.error.detail, 'Opps!', {
      timeOut: 3000,
    });
    if (req.url.includes('account/logout')) {
      return this.logout();
    }

    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;

      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.tokenSubject.next(null);
      const params = {
        refresh: localStorage.getItem('refresh'),
      };
      return this.authenticationService.refreshToken(params).pipe(
        switchMap((tokenData: any) => {
          if (tokenData) {
            this.cookie.set('access', tokenData.refresh);
            this.tokenSubject.next(tokenData.refresh);
            return next.handle(this.addToken(req, tokenData.refresh));
          }
          // If we don't get a new token, we are in trouble so logout.
          return this.logout();
        }),
        catchError(error => {
          // If there is an exception calling 'refreshToken', bad news so logout.
          return this.logout();
        }),
        finalize(() => {
          this.isRefreshingToken = false;
        }),
      );
    } else {
      return this.tokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(token => {
          return next.handle(this.addToken(req, token));
        }),
      );
    }
  }

  // If we don't get a new token, we are in trouble so logout.
  logout() {
    this.cookie.delete('access');
    this.cookie.deleteAll('access');
    this.cookie.deleteAll('refresh');
    location.reload();
    return observableThrowError('');
  }
}
