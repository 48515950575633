import { Injectable, NgZone } from '@angular/core';
import { environment } from 'environments/environment';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  private socket: WebSocket;
  private messageSubject = new Subject<any>();

  constructor(private ngZone: NgZone) {
    this.connect(environment.webSocket + 'status-update/');
  }

  connect(url: string): void {
    this.socket = new WebSocket(url);

    this.socket.onopen = event => {
      console.log('WebSocket connection established:', event);
    };

    this.socket.onmessage = event => {
      this.ngZone.run(() => {
        const message = JSON.parse(event.data);
        this.messageSubject.next(message); // Notify subscribers of new message
      });
    };

    this.socket.onclose = event => {
      console.log('WebSocket connection closed:', event);
    };

    this.socket.onerror = error => {
      console.error('WebSocket error:', error);
    };
  }

  sendMessage(message: any): void {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(JSON.stringify(message));
    }
  }

  close(): void {
    if (this.socket) {
      this.socket.close();
    }
  }

  get messages(): Observable<any> {
    return this.messageSubject.asObservable(); // Return an observable for messages
  }
}
