import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/auth/login',
  },
  {
    path: 'bordan-inventory-upload',
    loadChildren: () =>
      import(
        '@modules/boardanInventoryUpload/bordanInventoryUpload-routing.module'
      ).then(m => m.BordanInventoryUploadRoutingModule),
  },
  {
    path: 'static-files',
    loadChildren: () =>
      import('modules/staticfiles/staticfiles-routing.module').then(
        m => m.StaticFilesRoutingModule,
      ),
  },
  {
    path: 'return-report-upload',
    loadChildren: () =>
      import(
        'modules/returnReportupload/returnReportupload-routing.module'
      ).then(m => m.ReturnReportUploadRoutingModule),
  },
  {
    path: 'advance-search',
    loadChildren: () =>
      import('modules/advanceSearch/advance-search-routing.module').then(
        m => m.AdvanceSearchRoutingModule,
      ),
  },
  {
    path: 'bordan-location-upload',
    loadChildren: () =>
      import(
        'modules/bordanLocationupload/bordanLocationupload-routing.module'
      ).then(m => m.BordanLocationUploadRoutingModule),
  },
  {
    path: 'channel-advisor',
    loadChildren: () =>
      import('modules/ChannelAdvisor/channelAdvisor-routing.module').then(
        m => m.ChannelAdvisorRoutingModule,
      ),
  },
  {
    path: 'used-returns',
    loadChildren: () =>
      import('modules/usedReturns/used-returns-routing.module').then(
        m => m.UsedReturnRoutingModule,
      ),
  },
  {
    path: 'wrong-returns',
    loadChildren: () =>
      import('modules/wrongReturns/wrong-returns-routing.module').then(
        m => m.WrongReturnRoutingModule,
      ),
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('modules/auth/auth-routing.module').then(m => m.AuthRoutingModule),
  },
  {
    path: 'error',
    loadChildren: () =>
      import('modules/error/error-routing.module').then(
        m => m.ErrorRoutingModule,
      ),
  },
  {
    path: 'get-scanned',
    loadChildren: () =>
      import('modules/tables/tables-routing.module').then(
        m => m.TablesRoutingModule,
      ),
  },
  {
    path: 'user-management',
    loadChildren: () =>
      import('modules/user/returnReportupload-routing.module').then(
        m => m.UserManagementRoutingModule,
      ),
  },
  {
    path: 'bordan-resultants',
    loadChildren: () =>
      import('modules/bordanResultants/bordan-resultants-routing.module').then(
        m => m.BordanResultantsRoutingModule,
      ),
  },
  {
    path: 'process-images',
    loadChildren: () =>
      import('@modules/processImage/process-image-routing.module').then(
        m => m.PrcessImageRoutingModule,
      ),
  },
  {
    path: 'box-label',
    loadChildren: () =>
      import('modules/boxLabel/box-label-routing.module').then(
        m => m.BoxLabelRoutingModule,
      ),
  },
  {
    path: 'box-label-camera',
    loadChildren: () =>
      import('modules/boxLabelCamera/box-label-camera-routing.module').then(
        m => m.BoxLabelCameraRoutingModule,
      ),
  },
  {
    path: 'cleaning-images',
    loadChildren: () =>
      import('@modules/cleaningImages/cleaning-images-routing.module').then(
        m => m.CleaningImagesRoutingModule,
      ),
  },
  {
    path: 'customer-returns',
    loadChildren: () =>
      import('@modules/customerReturn/customer-return-routing.module').then(
        m => m.CustomerReturnRoutingModule,
      ),
  },
  {
    path: 'version',
    loadChildren: () =>
      import('modules/utility/utility-routing.module').then(
        m => m.UtilityRoutingModule,
      ),
  },
  {
    path: '**',
    pathMatch: 'full',
    loadChildren: () =>
      import('modules/error/error-routing.module').then(
        m => m.ErrorRoutingModule,
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
